import React, {useEffect, useContext, useRef} from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import MixpanelUtils from 'utils/mix-panel';
import NewRelicUtils from '@livongo/utilities/system/newrelic';

import {RegCodeAllowListProvider} from 'hook/RegCodeAllowListContext';
import {LanguageProvider, LanguageContext} from 'hook/LanguageContext';
import {ChatProvider} from 'hook/ChatContext';
import {GlobalProvider, GlobalContext} from 'hook/GlobalContext';
import useWindowSize from 'hook/useWindowSize';
import {
    landingPageLoad,
    setSuperAndPeopleProperties,
} from 'utils/mixpanel-utils';
import {removeNullFromProps, getCloudinaryImageUrl} from 'utils/utilities';

import FloatingBanner from 'components/floatingBanner';
import Footer from 'components/footer';
import HeroBlock from 'components/heroBlock';
// Content Blocks
import AppBlock from 'components/appBlock';
import BreakingDownBarriersBlock from 'components/breakingDownBarriersBlock';
import BannerMessage from 'components/bannerMessage';
import CarouselBlock from 'components/carouselBlock';
import CTABlock from 'components/ctaBlock';
import DeviceBlock from 'components/deviceBlock';
import FAQBlock from 'components/faqBlock';
import FocusBlock from 'components/focusBlock';
import PersonalizedSolutionBlock from 'components/personalizedSolutionBlock';
import ProgramBenefitsBlock from 'components/programBenefitsBlock';
import ProgramIconsBlock from 'components/programIconsBlock';
import ProgramOverviewBlock from 'components/programOverviewBlock';
import RatingBlock from 'components/ratingBlock';
import SupplyBlock from 'components/supplyBlock';
import TestimonialsBlock from 'components/testimonialsBlock';
import VideoBlock from 'components/videoBlock';
import WhatYouGetBlock from 'components/whatYouGetBlock';

import navyIcon from './../../static/Livongo-navy-logo.png';
import * as styles from './styles.module.scss';

const DEFAULT_LIVONGO_NUMBER = '1-800-945-4355';

const BLOCK_TYPES = Object.freeze({
    APP_BLOCK: 'ContentfulAppBlock',
    BREAKING_DOWN_BARRIERS_BLOCK: 'ContentfulBreakingDownBarriersBlock',
    CAROUSEL_BLOCK: 'ContentfulCarouselBlock',
    CTA_BLOCK: 'ContentfulCtaBlock',
    DEVICE_BLOCK: 'ContentfulDeviceBlock',
    FAQ_BLOCK: 'ContentfulFaqBlock',
    FOCUS_BLOCK: 'ContentfulFocusBlock',
    PERSONALIZED_SOLUTION_BLOCK: 'ContentfulPersonalizedSolutionBlock',
    PROGRAM_BENEFITS_BLOCK: 'ContentfulProgramBenefitsBlock',
    PROGRAM_ICONS_BLOCK: 'ContentfulProgramIconsBlock',
    PROGRAM_OVERVIEW_BLOCK: 'ContentfulProgramOverviewBlock',
    RATING_BLOCK: 'ContentfulRatingBlock',
    SUPPLY_BLOCK: 'ContentfulSupplyBlock',
    TESTIMONIALS_BLOCK: 'ContentfulTestimonialsBlock',
    VIDEO_BLOCK: 'ContentfulVideoBlock',
    WHAT_YOU_GET_BLOCK: 'ContentfulWhatYouGetBlock',
});

// list for migrating to livongo.com
const LIVONGO_REDIRECT = {
    '/GEN/DELL': 'dell',
};
const AMERICAN_ENGLISH = 'en-US';
const LIVONGO_URL = 'https://www.livongo.com/';

// TODO: these need to be replaced with environment variables
const SENTRY_DSN =
    'https://24d3bb9d4c1e4d58b749309856aeeea4@o501571.ingest.sentry.io/6139141';
let environment = 'PRODUCTION';
if (typeof window !== 'undefined') {
    if (window.location.host.includes('.local.livongo.com')) {
        environment = 'LOCAL';
    } else if (window.location.host.includes('.integration.livongo.com')) {
        environment = 'INTEGRATION';
    } else if (window.location.host.includes('.preprod.livongo.com')) {
        environment = 'PREPROD';
    }
}
Sentry.init({
    dsn: SENTRY_DSN,
    release: process.env.BUILD_NUMBER,
    environment,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,
    release: process.env.BUILD_TAG,
    environment: process.env.ENVIRONMENT,
});

// Update New Relic key values for this repository
const nrObject = {
    ACCOUNT_ID: process.env.ACCOUNT_ID,
    TRUST_KEY: process.env.TRUST_KEY,
    AGENT_ID: process.env.AGENT_ID,
    LICENSE_KEY: process.env.LICENSE_KEY,
    APPLICATION_ID: process.env.APPLICATION_ID,
};

// Initialize New Relic
if (typeof document !== 'undefined') {
    NewRelicUtils.init({document, nrObject});
}

const LandingPage = props => {
    const {width: windowWidth} = useWindowSize(); // don't remove, captures page resize for banner
    const bannerRef = useRef(null);
    const {language, updateTextStrings} = useContext(LanguageContext);
    const {setContactNumber} = useContext(GlobalContext);

    // Maps all the languages avaliable from Contentful to it's index
    const languageIndexes = props.data.allContentfulLandingPage.edges.reduce(
        (hash, edge, index) => {
            hash[edge.node.node_locale] = index;
            return hash;
        },
        {}
    );

    const languageProps =
        props.data.allContentfulLandingPage.edges[
            languageIndexes[language] || 0
        ].node;

    const {
        title,
        plNumber,
        contactNumber = DEFAULT_LIVONGO_NUMBER,
        // translations,
        searchEngineIndex = false,
        seoTags,
        commonTextStrings = {},
        headerBanner,
        floatingBanner,
        heroBlock,
        contentBlocks,
        footer,
    } = removeNullFromProps(languageProps);
    // TODO: we need to add the common text string to all landing pages
    const {textStrings, memberCommunicationPolicy} = commonTextStrings;
    // Add tab title to page
    if (typeof document !== 'undefined' && title) {
        document.title = title;
    }

    const redirectToLivongo = () => {
        if (typeof window !== 'undefined') {
            const path = window.location.pathname;

            if (LIVONGO_REDIRECT[path]) {
                window.location = `${LIVONGO_URL}${LIVONGO_REDIRECT[path]}`;
            }
        }
    };

    // If we have a language change, update the text
    useEffect(() => {
        if (textStrings) {
            updateTextStrings(textStrings);
        }
    }, [language]);

    // Initialize Mixpanel fire landing page event
    useEffect(() => {
        if (typeof window !== 'undefined') {
            MixpanelUtils.init(process.env.MIXPANEL_TOKEN);
            setSuperAndPeopleProperties();
            landingPageLoad();
        }

        setContactNumber(contactNumber);
    }, []);

    useEffect(() => {
        redirectToLivongo();
    }, []);

    return (
        <div className={styles.root}>
            {/* Populate title and metadata in the header for SEO purposes */}
            <ReactHelmet
                title={title}
                seoTags={seoTags}
                searchEngineIndex={searchEngineIndex}
            />
            {floatingBanner && (
                <FloatingBanner ref={bannerRef} {...floatingBanner} />
            )}
            <main
                role="main"
                style={{
                    paddingTop: bannerRef.current // Add additional padding at the top to make room for the banner
                        ? bannerRef.current.clientHeight
                        : 0,
                }}
            >
                {headerBanner && <BannerMessage {...headerBanner} />}
                {heroBlock && <HeroBlock {...removeNullFromProps(heroBlock)} />}
                {contentBlocks &&
                    contentBlocks.map((contentBlock, i) => {
                        return displayContentBlock(contentBlock, i);
                    })}
            </main>
            <Footer
                plNumber={plNumber}
                memberCommunicationPolicy={memberCommunicationPolicy}
                {...footer}
            />
        </div>
    );
};

const LandingPageContextWrapper = props => {
    const {translations = []} = removeNullFromProps(
        props.data.allContentfulLandingPage.edges[0].node
    );

    const availableLanguages = [AMERICAN_ENGLISH].concat(translations);

    return (
        <GlobalProvider>
            <RegCodeAllowListProvider>
                <LanguageProvider availableLanguages={availableLanguages}>
                    <ChatProvider>
                        <LandingPage {...props} />
                    </ChatProvider>
                </LanguageProvider>
            </RegCodeAllowListProvider>
        </GlobalProvider>
    );
};

const ReactHelmet = ({title, seoTags = {}, searchEngineIndex = false}) => {
    const {
        metaTags,
        links,
        googleOrganicResultsSchema,
        openGraphImage,
    } = seoTags;
    const openGraphImageUrl = getCloudinaryImageUrl(openGraphImage) || '';
    return (
        <Helmet>
            {/* CookiePro Cookies Consent Notice */}
            <script
                src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
                data-document-language="true"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script="212c4159-29e1-4386-8f87-00b4c9be94a5"
            ></script>
            <script type="text/javascript">
                {`function OptanonWrapper() { }`}
            </script>
            {/* End CookiePro Cookies Consent Notice */}

            <link rel="icon" type="image/png" href={navyIcon}></link>
            {title && <title>{title}</title>}
            {/*
                Use Google Search Console to debug whether URL page can be indexed. Visit https://search.google.com/search-console/inspect
                Follow steps in the Google Search Console and replace the content token below. Note: the content token is unique for each user.
                Step by step to test whether URL is indexed in the Search Engine:
                  1. Go to search engine (i.e. https://www.google.com)
                  2. Type "site:<url>"
                  3. If it shows up in the search result, that means the URL page is indexed.
                Note: it takes a while to reflect changes in Search Engine (4 days to few weeks depending on the total of pages).
             */}
            <meta
                name="google-site-verification"
                content="BUOwYUzC1pI37DVxyjSMlRWBY-TgeiDyijQ8TizVW2c"
            />
            {!searchEngineIndex && [
                // Document: https://developers.google.com/search/docs/advanced/crawling/special-tags
                <meta name="robots" content="noindex,nofollow" />, // Apply to most search engine
            ]}
            {searchEngineIndex && [
                // Document: https://developers.google.com/search/docs/advanced/crawling/special-tags
                <meta name="robots" content="index,follow" />, // Apply to most search engine
            ]}
            {metaTags &&
                metaTags.map(metaTag => {
                    let metaData = {
                        [metaTag.nameAttibuteType ||
                        'name']: metaTag.nameAttibute,
                    };
                    if (metaTag.content) {
                        metaData['content'] = metaTag.content;
                    }
                    return <meta key={metaTag.nameAttibute} {...metaData} />;
                })}
            {links &&
                links.map(link => {
                    let metaData = {
                        [link.nameAttibuteType || 'rel']: link.nameAttibute,
                    };
                    if (link.content) {
                        metaData['href'] = link.content;
                    }
                    return <link key={link.nameAttibute} {...metaData} />;
                })}
            {googleOrganicResultsSchema?.internal?.content && (
                <script type="application/ld+json">
                    {seoTags?.googleOrganicResultsSchema.internal.content}
                </script>
            )}
            {openGraphImageUrl && (
                <meta property="og:image" content={openGraphImageUrl} />
            )}
        </Helmet>
    );
};

const displayContentBlock = (props, i) => {
    switch (props.__typename) {
        case BLOCK_TYPES.APP_BLOCK:
            return <AppBlock key={i} {...props} />;
        case BLOCK_TYPES.BREAKING_DOWN_BARRIERS_BLOCK:
            return <BreakingDownBarriersBlock key={i} {...props} />;
        case BLOCK_TYPES.CAROUSEL_BLOCK:
            return <CarouselBlock key={i} {...props} />;
        case BLOCK_TYPES.CTA_BLOCK:
            return <CTABlock key={i} {...props} />;
        case BLOCK_TYPES.DEVICE_BLOCK:
            return <DeviceBlock key={i} {...props} />;
        case BLOCK_TYPES.FAQ_BLOCK:
            return <FAQBlock key={i} {...props} />;
        case BLOCK_TYPES.FOCUS_BLOCK:
            return <FocusBlock key={i} {...props} />;
        case BLOCK_TYPES.PERSONALIZED_SOLUTION_BLOCK:
            return <PersonalizedSolutionBlock key={i} {...props} />;
        case BLOCK_TYPES.PROGRAM_BENEFITS_BLOCK:
            return <ProgramBenefitsBlock key={i} {...props} />;
        case BLOCK_TYPES.PROGRAM_ICONS_BLOCK:
            return <ProgramIconsBlock key={i} {...props} />;
        case BLOCK_TYPES.PROGRAM_OVERVIEW_BLOCK:
            return <ProgramOverviewBlock key={i} {...props} />;
        case BLOCK_TYPES.RATING_BLOCK:
            return <RatingBlock key={i} {...props} />;
        case BLOCK_TYPES.SUPPLY_BLOCK:
            return <SupplyBlock key={i} {...props} />;
        case BLOCK_TYPES.TESTIMONIALS_BLOCK:
            return <TestimonialsBlock key={i} {...props} />;
        case BLOCK_TYPES.VIDEO_BLOCK:
            return <VideoBlock key={i} {...props} />;
        case BLOCK_TYPES.WHAT_YOU_GET_BLOCK:
            return <WhatYouGetBlock key={i} {...props} />;
        default:
            return null;
    }
};

export default LandingPageContextWrapper;

export const query = graphql`
    fragment CloudinaryImage on ContentfulCloudinaryImage {
        name
        image {
            url
            height
            width
        }
    }

    fragment CloudinaryVideo on ContentfulCloudinaryVideo {
        name
        video {
            url
            height
            width
        }
    }

    fragment CTAButton on ContentfulCtaButton {
        buttonText
        url
        backgroundColor
        size
        customization
    }

    fragment StarbadgeType on ContentfulStarbadge {
        badgeType
        backgroundColor
    }

    fragment SignInButton on ContentfulSignInButton {
        url
        buttonText
        backgroundColor
    }

    fragment BackgroundBlock on ContentfulBackgroundBlock {
        name
        textColorHex
        backgroundColorHex
        backgroundGradient
        backgroundImage {
            ...CloudinaryImage
        }
        orientation
        backgroundImageMaxWidth
        backgroundImagePadding
        desktopMinimumHeight
        desktopTopPadding
        desktopContainerTopPadding
        desktopContainerBottomPadding
        maxContainerWidth
        mobileBackgroundImage {
            ...CloudinaryImage
        }
        mobileBackgroundOrientation
        mobileBackgroundImageMaxWidth
        mobileBackgroundImagePadding
        customization
    }

    query($slug: String!) {
        allContentfulLandingPage(filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    id
                    name
                    title
                    slug
                    plNumber
                    contactNumber
                    node_locale
                    translations
                    searchEngineIndex
                    seoTags {
                        name
                        metaTags {
                            nameAttibute
                            nameAttibuteType
                            content
                        }
                        links {
                            nameAttibute
                            nameAttibuteType
                            content
                        }
                        googleOrganicResultsSchema {
                            internal {
                                content
                            }
                        }
                        openGraphImage {
                            ...CloudinaryImage
                        }
                    }
                    commonTextStrings {
                        textStrings {
                            key
                            text
                        }
                        memberCommunicationPolicy {
                            raw
                        }
                    }
                    floatingBanner {
                        name
                        backgroundColorHex
                        textColorHex
                        content {
                            raw
                        }
                        ctaText
                        ctaLink
                        ctaColorHex
                        stickToTop
                    }
                    headerBanner {
                        name
                        backgroundColorHex
                        content {
                            raw
                        }
                        ctaText
                        ctaColorHex
                        extendedContent {
                            extendedContent
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                    heroBlock {
                        ...HeroBlock
                    }
                    contentBlocks {
                        ... on ContentfulAppBlock {
                            __typename
                            ...AppBlock
                        }
                        ... on ContentfulBreakingDownBarriersBlock {
                            __typename
                            ...BreakingDownBarriersBlock
                        }
                        ... on ContentfulCarouselBlock {
                            __typename
                            ...CarouselBlock
                        }
                        ... on ContentfulCtaBlock {
                            __typename
                            ...CTABlock
                        }
                        ... on ContentfulDeviceBlock {
                            __typename
                            ...DeviceBlock
                        }
                        ... on ContentfulFaqBlock {
                            __typename
                            ...FAQBlock
                        }
                        ... on ContentfulFocusBlock {
                            __typename
                            ...FocusBlock
                        }
                        ... on ContentfulPersonalizedSolutionBlock {
                            __typename
                            ...PersonalizedSolutionBlock
                        }
                        ... on ContentfulProgramBenefitsBlock {
                            __typename
                            ...ProgramBenefitsBlock
                        }
                        ... on ContentfulProgramOverviewBlock {
                            __typename
                            ...ProgramOverviewBlock
                        }
                        ... on ContentfulProgramIconsBlock {
                            __typename
                            ...ProgramIconsBlock
                        }
                        ... on ContentfulRatingBlock {
                            __typename
                            ...RatingBlock
                        }
                        ... on ContentfulSupplyBlock {
                            __typename
                            ...SupplyBlock
                        }
                        ... on ContentfulTestimonialsBlock {
                            __typename
                            ...TestimonialsBlock
                        }
                        ... on ContentfulVideoBlock {
                            __typename
                            ...VideoBlock
                        }
                        ... on ContentfulWhatYouGetBlock {
                            __typename
                            ...WhatYouGetBlock
                        }
                    }
                    footer {
                        __typename
                        ...Footer
                    }
                }
            }
        }
    }
`;
